import { FaCcVisa, FaCcMastercard } from "react-icons/fa";
import s from "./Footer.module.css";
import Logo from "./logo.svg";
import ApplePayIcon from "./Vector (2).svg";

const Footer = () => {
  return (
    <div className={s.footer}>
      <div className={s.container}>
        <div className={s.footerInner}>
          <div className={s.footerLogo}>
            <img src={Logo} alt="Logo" width="100" height="60" />
          </div>

          <div className={s.documents}>
            <a
              href="/privacy-policy.pdf"  // Ссылка на PDF в папке public
              target="_blank"             // Открытие в новой вкладке
              rel="noopener noreferrer"   // Безопасный переход
              className={s.privacyPolicy} // Стилизация ссылки
            >
              Политика конфиденциальности
            </a>
          </div>

          <div className={s.footerLogos}>
            <FaCcVisa className={s.iconPayments} />
            <FaCcMastercard className={s.iconPayments} />
            <img src={ApplePayIcon} className={s.iconPayments} alt="Apple Pay" />
          </div>
        </div>
      </div>

      <div className={s.copyright}>
        <div className={s.container}>
          <div className={s.copyrightText}>
            <div>© 2024 МАСТЕР-КЛАСС</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
