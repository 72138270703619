import React, { useEffect, useState } from "react";
import s from "./Card.module.css";
import Modal from "./Modal";
import Popup from "./Popup";

// Импорт SVG
import Acquiring from "./1.svg";
import Cards from "./2.svg";
import QR from "./3.svg";
import WhiteLabel from "./4.svg";
import LogoSVG from "./ente.svg";

const state = {
  cards: [
    {
      id: 1,
      title: "E-Commerce",
      descriptionOne: "Лучшие способы оплаты для интернет-торговли",
      image: Acquiring,
      popups: {
        title: "E-Commerce",
        photo: Acquiring,
        infoTextOne:
          "Все способы оплаты на одной платформе: эквайринг, подписки и аналитика. Упрощаем оплату для вашего бизнеса — удобно, быстро и безопасно",
      },
    },
    {
      id: 2,
      title: "МФО",
      descriptionOne: "Эффективные решения для управления займами",
      image: Cards,
      popups: {
        title: "МФО",
        photo: Cards,
        infoTitle: "Счета и платежи для МФО",
        infoTextOne:
          "Быстрая выдача займов и удобные погашения. Поддержка разных типов комиссий и онлайн-оплат",
      },
    },
    {
      id: 3,
      title: "Агрегаторам",
      descriptionOne: "Решения для платформ-агрегаторов услуг",
      image: QR,
      popups: {
        title: "Платежи по QR-коду",
        photo: QR,
        infoTitle: "Оплата по QR-коду",
        infoTextOne: "— Оплата без карты и наличных",
        infoTextTwo: "— Быстрое подключение за 1 рабочий день",
        infoTextThree: "— Без сложной интеграции",
        infoTextFour: "— Высокий уровень безопасности",
      },
    },
    {
      id: 4,
      title: "White Label",
      descriptionOne:
        "Создайте собственные финансовые сервисы под вашим брендом",
      image: WhiteLabel,
      popups: {
        title: "White Label",
        photo: WhiteLabel,
        infoTitle: "White Label решение",
        infoTextOne:
          "Может работать как основное, предобработочное или маршрутизирующее решение",
        infoTextTwo: "— Выплаты на банковские карты",
        infoTextThree: "— IFRAME-оплата",
        infoTextFour: "— Интернет-эквайринг",
        infoTextFive: "— Регулярные платежи",
        infoTextSix: "— Оплата по QR-коду",
      },
    },
  ],
};

const Card = () => {
  const [modalActive, setModalActive] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.code === "Escape") {
        document.body.style.overflow = "visible";
        setSelectedItem(null);
        setModalActive(false);
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);

  const openModal = (popups) => {
    document.body.style.overflow = "hidden";
    setModalActive(true);
    setSelectedItem(popups);
  };

  const closeModal = () => {
    document.body.style.overflow = "visible";
    setModalActive(false);
    setSelectedItem(null);
  };

  return (
    <div className={s.vertical}>
      <div className={s.verticalBody}>
        <div className={s.verticalRow}>
          {state.cards.map((card) => (
            <div key={card.id} className={s.verticalColumn}>
              <div className={s.verticalItem}>
                <div className={s.verticalLabel}>
                  <img src={card.image} alt={card.title} />
                </div>
                <div className={s.verticalText}>
                  <h3>{card.title}</h3>
                  <p>{card.descriptionOne}</p>
                </div>

                <button
                  onClick={() => openModal(card)}
                  className={s.verticalBtn}
                >
                  <span className={s.btnText}>Подробнее</span>
                  <img src={LogoSVG} alt="логотип" className={s.icon} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal active={selectedItem !== null} modalActive={modalActive}>
        {selectedItem && (
          <Popup popups={selectedItem.popups} closeModal={closeModal} />
        )}
      </Modal>
    </div>
  );
};

export default Card;
