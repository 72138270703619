import s from "./Main.module.css";
import team from "./logo.svg"; // Логотип команды
import bg from "./bg.svg"; // Фоновое изображение

function Main() {
  return (
    <div className={s.about}>
      <img className={s.bgImage} src={bg} alt="Фон" />
      <div className={s.container}>
        <div className={s.aboutInner}>
          <div className={s.aboutContent}>
            <h1 className={s.aboutHeader}>
              Финансовые решения для бизнеса любого масштаба
            </h1>
            <div className={s.aboutInfo}>
              <p>
                Наша цель — обеспечить безопасность и удобство онлайн-платежей,
                сделав их доступными везде, а также предложить рынку лучший
                способ осуществления платежей.
              </p>
              <p>
                Разрабатывайте собственные продукты, используя интеграцию с API
                или готовые решения для конечных пользователей.
              </p>
            </div>
          </div>

          {/* Изображение команды */}
          <img
            className={s.aboutImage}
            src={team}
            alt="Изображение команды"
          />
        </div>
      </div>
    </div>
  );
}

export default Main;
