import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import s from "./Contact.module.css";

function Contact() {
  return (
    <div className={s.map} id="contact">
      <div className={s.container}>
        <div className={s.header}>
          <h2 className={s.mapTitle}>Контакты</h2>
        </div>

        <div className={s.contactInfoWrapper}>
          <div className={s.contactItem}>
            <BsFillTelephoneFill className={s.icon} />
            <strong className={s.label}>Телефон:</strong>
            <span className={s.value}>
              <a href="tel:+7-996-927-71-70">+7-996-927-71-70</a>
            </span>
          </div>
          <div className={s.contactItem}>
            <MdEmail className={s.icon} />
            <strong className={s.label}>Почта:</strong>
            <span className={s.value}>
              <a href="mailto:mkclass.ru@gmail.com">mkclass.ru@gmail.com</a>
            </span>
          </div>
          <div className={s.contactItem}>
            <strong className={s.label}>Компания:</strong>
            <span className={s.value}>ООО "МАСТЕР-КЛАСС"</span>
          </div>
          <div className={s.contactItem}>
            <strong className={s.label}>ИНН:</strong>
            <span className={s.value}>3309004775</span>
          </div>
        </div>

        <div className={s.mapWrapper}>
          <div className={s.mapBlock}>
            <iframe 
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa5f89206b42be0a86520771068f9c8cf401f76108d4997dd77c5fa6b8f50819c&amp;source=constructor" 
              width="100%" 
              height="300" 
              frameBorder="0"
            ></iframe>
            <a
              href="https://yandex.ru/maps/?text=Владимирская%20область,%20Собинский%20муниципальный%20округ,%20Лакинск,%20улица%20Мира,%2090Г"
              target="_blank"
              rel="noopener noreferrer"
              className={s.mapAddress}
            >
              Лакинск, улица Мира, 90Г
            </a>
          </div>

          <div className={s.mapBlock}>
            <iframe 
              src="https://yandex.ru/map-widget/v1/?um=constructor%3Acf865b7d217dc948ed469a895a3aed33771bbd6f3172e2e75943590a0f5eabe6&amp;source=constructor" 
              width="100%" 
              height="300" 
              frameBorder="0"
            ></iframe>
            <a
              href="https://yandex.ru/maps/?text=Ярославль,%20улица%20Наумова,%2065/1"
              target="_blank"
              rel="noopener noreferrer"
              className={s.mapAddress}
            >
              Ярославль, улица Наумова, 65/1
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
