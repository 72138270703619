import s from "./About.module.css";
import team from "./logo.svg";

function About() {
  return (
    <div className={s.about} id="about">
      <div className={s.container}>
        {/* Заголовок раздела */}
        <div className={s.aboutHeader}>
          <h2 className={s.aboutTitle}>О КОМПАНИИ</h2>
        </div>
        
        {/* Контент с изображением и информацией */}
        <div className={s.aboutInner}>
          <img className={s.aboutImage} src={team} alt="Изображение Команды" />
          <div className={s.aboutContent}>
            <div className={s.aboutInfo}>
              <p>
                Платежный агрегатор <b>Мастер Класс</b> — это ваш надежный партнер в мире электронных платежей и универсальное решение для бизнеса любого масштаба
              </p>
              <p>
                Предоставляет готовые решения для интернет-магазинов, МФО, агрегаторов услуг и компаний, нуждающихся в платформах White Label. Наша цель — сделать платежи максимально простыми, быстрыми и безопасными
              </p>
              <p>
                Мы не просто агрегатор, а партнёр, который помогает бизнесу расти, автоматизировать процессы и улучшать клиентский сервис
              </p>
            </div>
          </div>
        </div>

        {/* Юридическая информация */}
{/*         <div className={s.aboutDocuments}>
          <h3 className={s.aboutSubTitle}>Юридическая информация</h3>
          <div className={s.documents}>
            <div className={s.aboutPrivacyPolicy}>
              <a href="https://ay-money.kz/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
                Политика конфиденциальности
              </a>
            </div>
            <div className={s.aboutOferta}>
              <a href="https://ay-money.kz/oferta.pdf" target="_blank" rel="noopener noreferrer">
                Публичный договор оферты
              </a>
            </div>
            <div className={s.aboutLicense}>
              <a href="https://ay-money.kz/license.pdf" target="_blank" rel="noopener noreferrer">
                Лицензия
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
