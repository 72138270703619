import Card from "./Card";
import s from "./Services.module.css";

const Services = () => {
  return (
    <div className={s.services} >
      <div className={s.container}>
      <div className={s.servicesHeader}>
          <h2 className={s.servicesTitle}>Кому подходят наши решения</h2>
        </div>
        <Card />
      </div>
    </div>
  );
};

export default Services;
