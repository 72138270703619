import ec from './ec.svg';
import ex from './ex.svg';
import guard from './guard.svg';
import int from './int.svg';
import rub from './rub.svg';
import sbp from './sbp.svg';
import s from "./PanelOne.module.css";

const PanelOne = () => {
  return (
    <div className={s.section} id="services">
      <div className={s.container}>
        <div className={s.sectionHeader}>
          <h2 className={s.sectionTitle}>НАШИ РЕШЕНИЯ</h2>
        </div>
        <div className={s.services}>
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
          <img src={ec}  className={s.servicesIcon} />
            <div className={s.servicesTitle}>интернет-эквайринг</div>
            <div className={s.servicesText}>
            Онлайн-платежи для любого бизнеса
            </div>
          </div>
          <hr className={s.hrOne} />
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
          <img src={ex}  className={s.servicesIcon} />
            <div className={s.servicesTitle}>Денежные переводы</div>
            <div className={s.servicesText}>
            Мгновенные денежные переводы с карты на карту
            </div>
          </div>
          <hr className={s.hrTwo} />
          <div className={`${s.servicesItem} ${s.servicesItemBorder}`}>
          <img src={guard}  className={s.servicesIcon} />
            <div className={s.servicesTitle}>Высокая безопасность</div>
            <div className={s.servicesText}>
            Сертификация в соответствии с Положением Банка России №382-П и PCI DSS Level 1
            </div>
          </div>
          <hr className={s.hrThree} />
          <div className={`${s.servicesItem} ${s.servicesItemBorderFivteen}`}>
          <img src={int} className={s.servicesIcon} />
            <div className={s.servicesTitle}>Простая интеграция</div>
            <div className={s.servicesText}>Любая интеграция через API</div>
          </div>
          <hr className={s.hrTwo} />
          <div className={s.servicesItem}>
          <img src={rub}  className={s.servicesIcon} />
            <div className={s.servicesTitle}>Цифровой рубль</div>
            <div className={s.servicesText}>
            Возможность использования Цифрового рубля (Soon)
            </div>
          </div>
          <hr className={s.hrOne} />
          <div className={s.servicesItem}>
          <img src={sbp} className={s.servicesIcon} />
            <div className={s.servicesTitle}>СБП</div>
            <div className={s.servicesText}>
            Оплата через Систему Быстрых Платежей
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelOne;
