import { useState } from "react";
import { Link } from "react-scroll";
import logo from "./logo.svg";
import s from "./Header.module.css";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);

  return (
    <header className={s.header} id="header">
      <div className={s.container}>
        <div className={s.headerInner}>
          <Link
            className={s.headerLogo}
            to="header"
            smooth={true}
            duration={1000}
            offset={-10000}
            onClick={closeMenu}
          >
            <img src={logo} alt="Logo" width="116" height="34" />
          </Link>

          {/* Десктопное меню */}
          <nav className={s.navDesktop}>
            <Link
              className={s.navLink}
              to="services"
              smooth={true}
              duration={500}
              offset={-70}
            >
              Наши Решения
            </Link>
            <Link
              className={s.navLink}
              to="about"
              smooth={true}
              duration={500}
              offset={-70}
            >
              О Компании
            </Link>
            <Link
              className={s.navLink}
              to="contact"
              smooth={true}
              duration={500}
              offset={-70}
            >
              Контакты
            </Link>
            <Link
              className={s.navLink}
              to="faq"
              smooth={true}
              duration={500}
              offset={-70}
            >
              FAQ
            </Link>
            <Link
              className={s.navLink}
              to="ask"
              smooth={true}
              duration={500}
              offset={-70}
            >
              Оставить заявку
            </Link>
          </nav>

          {/* Бургер-меню */}
          <button
            className={`${s.burger} ${menuOpen ? s.open : ""}`}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span />
            <span />
            <span />
          </button>
        </div>

        {/* Мобильное меню */}
        <div className={`${s.overlay} ${menuOpen ? s.overlayOpen : ""}`}>
          <nav className={s.navMobile}>
            <Link
              className={s.navLink}
              to="services"
              smooth={true}
              duration={500}
              offset={-70}
              onClick={closeMenu}
            >
              Наши Решения
            </Link>
            <Link
              className={s.navLink}
              to="about"
              smooth={true}
              duration={500}
              offset={-70}
              onClick={closeMenu}
            >
              О Компании
            </Link>
            <Link
              className={s.navLink}
              to="contact"
              smooth={true}
              duration={500}
              offset={-70}
              onClick={closeMenu}
            >
              Контакты
            </Link>
            <Link
              className={s.navLink}
              to="faq"
              smooth={true}
              duration={500}
              offset={-70}
              onClick={closeMenu}
            >
              FAQ
            </Link>
            <Link
              className={s.navLink}
              to="ask"
              smooth={true}
              duration={500}
              offset={-70}
              onClick={closeMenu}
            >
              Оставить заявку
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
