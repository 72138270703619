import "./App.css";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Contact from "./components/Contact/Contact";
import Services from "./components/Services/Services";
import PanelOne from "./components/PanelOne/PanelOne";
import FAQPage from "./components/FAQPage/FAQPage";
import Main from "./components/Main/Main";
import ContactBlock from "./components/ContactBlock/ContactBlock";

function App() {
  return (
    <div className="App">
      <Header />
      <Main/>
      <PanelOne />
      <Services />
      <About />
      <Contact />
      <FAQPage />
      <ContactBlock/>
      <Footer />
    </div>
  );
}

export default App;
