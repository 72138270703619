import React from "react";
import Accordion from "./Accordion";
import s from "./FAQPage.module.css";

const FAQPage = () => {
  const faqSections = [
    {
      title: "Сколько стоит подключение к услуге?",
      content:
        "Подключение бесплатное, мы берем только комиссию с каждой транзакции. Ее размер зависит от оборота и особенностей бизнеса. Кроме того, мы бесплатно предоставляем техническую поддержку и закрепляем за вами персонального менеджера, который доступен круглосуточно",
    },
    {
      title: "Какие документы необходимы для подключения?",
      content:
        "Список необходимых документов предоставит ваш персональный менеджер",
    },
    {
      title: "Можно ли подключить интернет-эквайринг без сайта?",
      content:
        "Мы предоставляем возможность выставлять счета на оплату из личного кабинета по ссылке в социальных сетях, в мессенджерах, через SMS или электронную почту. Ссылка на оплату может быть размещена в виде QR-кода, в том числе на физическом носителе: буклете, баннере, листовке",
    },
    {
      title: "Поддерживаете ли вы интеграцию с другими сервисами?",
      content:
        "Да, наша платформа поддерживает API для интеграции с другими системами",
    },
    {
      title: "Как вы обрабатываете и храните данные?",
      content:
        "Все данные хранятся в зашифрованном виде и обрабатываются в соответствии с политикой конфиденциальности и нормами",
    },
  ];

  return (
    <div className={s.faqPage} id="faq">
      <div className={s.container}>
        <div className={s.faqHeader}>
          <h2 className={s.faqTitle}>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
        </div>
        <Accordion sections={faqSections} />
      </div>
    </div>
  );
};

export default FAQPage;
